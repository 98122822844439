<template>
  <el-button type="danger" size="mini" @click="handleDelete">
    <i class="fa fa-trash-alt" />
  </el-button>
</template>
<script>
export default {
  props: {
    onDelete: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    async handleDelete() {
      try {
        await this.$confirm(
          this.$t("message.areYouSure"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }

      try {
        await this.onDelete();
        this.$message.success(this.$t("message.delete_success"));
      } catch ({ status, status_code }) {
        const statusCode = status || status_code;
        if (statusCode !== 403 && statusCode !== 402) {
          this.$message.error(this.$t("message.something_went_wrong"));
        }
      }
    }
  }
};
</script>
