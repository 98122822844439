var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Heading',{staticClass:"mb-4",attrs:{"heading":"h4","content":"Progress Reports"}},[(_vm.roleIsTeacherOrAdmin)?_c('router-link',{attrs:{"slot":"button","to":{
        name: 'sessionClassesCreateReport',
        params: { classId: _vm.$route.params.classId }
      }},slot:"button"},[_c('el-button',{attrs:{"type":"primary","size":"mini"}},[_c('i',{staticClass:"fas fa-plus"})])],1):_vm._e()],1),_c('hr'),(
      (_vm.isRoleAdmin() || _vm.isRoleTeacher()) &&
        _vm.progressReportsCompletedCount < _vm.progressReportsNeededCount
    )?_c('el-alert',{staticStyle:{"margin":"20px 0px"},attrs:{"show-icon":"","type":"warning"}},[_c('template',{slot:"title"},[_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.$t('message.teacherNeedToWriteReports', {
            howManyReportNeedToWrite: _vm.progressReportsNeededCount,
            writtenReports: _vm.progressReportsCompletedCount
          })
        )}})])],2):_vm._e(),(_vm.roleIsTeacherOrAdmin)?_c('el-card',{directives:[{name:"loading",rawName:"v-loading",value:(!_vm.session_class),expression:"!session_class"}],attrs:{"element-loading-spinner":"el-icon-loading"}},[(_vm.session_class)?_c('div',_vm._l((_vm.session_class.session_class_teachers),function(teacher){return _c('div',{key:teacher.id},[_c('Heading',{staticClass:"mb-4",attrs:{"heading":"h6","content":_vm.user.displayName(
              teacher.teacher_user.first_name,
              teacher.teacher_user.last_name
            )}}),_c('ClassReports',{attrs:{"isPrivateLesson":false,"className":_vm.session_class.title,"classId":_vm.$route.params.classId,"reports":_vm.classReports[teacher.teacher_user.id]},on:{"fetchReport":_vm.fetchReport}})],1)}),0):_vm._e()]):_c('el-card',[_c('el-tabs',{on:{"tab-click":() => {}},model:{value:(_vm.currenctReportTab),callback:function ($$v) {_vm.currenctReportTab=$$v},expression:"currenctReportTab"}},_vm._l((_vm.students),function({ student }){return _c('el-tab-pane',{key:student.id,attrs:{"label":student.name,"name":String(student.id)}},[_c('template',{slot:"label"},[_c('i',{staticClass:"far fa-user"}),_vm._v(" "+_vm._s(student.name)+" ")]),_c('StudentReports',{attrs:{"isPrivateLesson":false,"classId":_vm.$route.params.classId,"reports":_vm.studentReports[student.id]},on:{"fetchReport":_vm.fetchReport}})],2)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }