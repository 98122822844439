<template>
  <div>
    <Heading heading="h4" content="Progress Reports" class="mb-4">
      <router-link
        slot="button"
        v-if="roleIsTeacherOrAdmin"
        :to="{
          name: 'sessionClassesCreateReport',
          params: { classId: $route.params.classId }
        }"
      >
        <el-button type="primary" size="mini">
          <i class="fas fa-plus" />
        </el-button>
      </router-link>
    </Heading>
    <hr />
    <el-alert
      v-if="
        (isRoleAdmin() || isRoleTeacher()) &&
          progressReportsCompletedCount < progressReportsNeededCount
      "
      style="margin: 20px 0px"
      show-icon
      type="warning"
    >
      <template slot="title">
        <span
          v-html="
            $t('message.teacherNeedToWriteReports', {
              howManyReportNeedToWrite: progressReportsNeededCount,
              writtenReports: progressReportsCompletedCount
            })
          "
        />
      </template>
    </el-alert>
    <el-card
      v-if="roleIsTeacherOrAdmin"
      v-loading="!session_class"
      element-loading-spinner="el-icon-loading"
    >
      <div v-if="session_class">
        <div
          :key="teacher.id"
          v-for="teacher in session_class.session_class_teachers"
        >
          <Heading
            heading="h6"
            :content="
              user.displayName(
                teacher.teacher_user.first_name,
                teacher.teacher_user.last_name
              )
            "
            class="mb-4"
          ></Heading>
          <ClassReports
            :isPrivateLesson="false"
            :className="session_class.title"
            :classId="$route.params.classId"
            :reports="classReports[teacher.teacher_user.id]"
            @fetchReport="fetchReport"
          />
        </div>
      </div>
    </el-card>
    <el-card v-else>
      <el-tabs v-model="currenctReportTab" @tab-click="() => {}">
        <el-tab-pane
          :key="student.id"
          v-for="{ student } in students"
          :label="student.name"
          :name="String(student.id)"
        >
          <template slot="label">
            <i class="far fa-user" />
            {{ student.name }}
          </template>
          <StudentReports
            :isPrivateLesson="false"
            :classId="$route.params.classId"
            :reports="studentReports[student.id]"
            @fetchReport="fetchReport"
          />
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user } from "@ivy-way/material";
import moment from "moment";
import { helper } from "@ivy-way/material";
import roleMixin from "@/mixins/role";
import profileTest from "@/mixins/profileTest";
import courseApi from "@/apis/course";
import reportApi from "@/apis/report";

import StudentReports from "@/views/report/StudentReports";
import ClassReports from "@/views/report/ClassReports";

export default {
  metaInfo() {
    return {
      title: "Info"
    };
  },

  components: {
    StudentReports,
    ClassReports
  },

  mixins: [roleMixin, profileTest],
  props: [],
  data() {
    return {
      session_class: null,
      lessonLogMode: "list",
      classReports: [],
      studentReports: [],
      progressReportsCompletedCount: 0,
      progressReportsNeededCount: 0,
      currenctReportTab: "teacher",
      students: []
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    user() {
      return user;
    },
    helper() {
      return helper;
    },
    roleIsTeacherOrAdmin() {
      return this.isRoleAdmin() || this.isRoleTeacher();
    },
    displayStudentUserIds() {
      if (this.roleIsTeacherOrAdmin) {
        return null;
      } else {
        if (this.isRoleStudent()) {
          return [this.$store.getters["user/getProfile"].id];
        } else if (this.isRoleParent()) {
          return this.$store.getters["user/getProfile"].student_ids
            ? this.$store.getters["user/getProfile"].student_ids
            : [];
        } else {
          return [];
        }
      }
    }
  },
  watch: {
    students() {
      if (
        0 < this.students.length &&
        (this.isRoleParent() || this.isRoleStudent())
      ) {
        this.currenctReportTab = String(this.students[0].student.id);
      }
    }
  },

  mounted() {
    this.getSessions();
    this.fetchReport();
  },

  methods: {
    async getSessions() {
      const { session_class } = await courseApi.fetchSessionClass(
        this.$route.params.classId
      );
      this.session_class = session_class;
      this.students = session_class.student_class_tickets;

      if (this.displayStudentUserIds) {
        this.students = this.students.filter(({ student_user_id }) =>
          this.displayStudentUserIds.includes(student_user_id)
        );
      }

      if (this.isRoleTeacher()) {
        const teachers = session_class.session_class_teachers;
        if (teachers.length > 0) {
          this.currenctReportTab = String(teachers[0].teacher_user.id);
        }
      }
    },
    async fetchReport() {
      const {
        class_progress_reports_group_by_user: classReports,
        student_progress_reports_group_by_user: studentReports,
        progress_reports_completed_count,
        progress_reports_needed_count
      } = await reportApi.fetchReports(this.$route.params.classId);
      // if (this.teacher) {
      this.classReports = classReports;
      this.studentReports = studentReports;
      this.progressReportsCompletedCount = progress_reports_completed_count;
      this.progressReportsNeededCount = progress_reports_needed_count;
      // }
    }
  }
};
</script>

<style scoped></style>
